// src/Section4.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section3.css'; 

const Section3 = () => {
  return (
    <Container className="section4 text-center" id='joinus'>
      <Row>
        <Col>
        <h1 className="section4-title">Join us</h1>
          <p className="section4-text">
          our ladies are based. they love u🫶 </p>


        <img src="3.png" alt="tweet" className="img-fluid vibration" />
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
