// src/NavBar.js
import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './NavBar.css';

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavClick = () => setExpanded(false);

  return (
    <>
      <Navbar expanded={expanded} variant="dark" expand="lg" fixed="top" className="custom-navbar">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src="/logo.svg"
              width="100"
              height="100"
              className="d-inline-block align-top vibration"
              alt="Maxx logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}>
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="#overview" onClick={handleNavClick}>Overview</Nav.Link>
              <Nav.Link href="#tokenomics" onClick={handleNavClick}>Tokenomics</Nav.Link>
              <Nav.Link href="#joinus" onClick={handleNavClick}>Join us</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link 
                href="" 
                className="btn btn-primary text-white" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Buy $BLADY
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
