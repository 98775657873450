import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Section1.css';

const Section1 = () => {
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  return (
    <Container className="section1 my-5" id='overview'>
      <Row className="align-items-center">
        <Col lg={6} md={12} className="text-section" data-aos="fade-right" data-aos-duration="4000">
        <h1 className='bort-title'>$BLADY</h1>
          <h1 className='bort-title2'>our ladies are based. they love u🫶</h1>
        </Col>
        <Col lg={6} md={12} className="image-section text-center" data-aos="fade-left" data-aos-duration="4000">
          <img src="wendy.png" alt="wendy" className="img-fluid vibration" />
        </Col>
      </Row>
    </Container>
  );
};

export default Section1;
